<template>
        <div class="outer-wrapper">
                <div class="one-top-wrapper">
                        <span>无搜索结果</span>
                </div>
                <div class="down-wrapper">
                    <div class='down-inner-wrapper'>       
                    </div>
                </div>
            </div>
</template>
<script>
    /*let userToken = localStorage.getItem("current_user_token");*/
    export default {
        name: 'searchResult',
        components: {
        },
        data() {
            return {
                
            }
        },
        props: {
        },
        created() {
        },
        methods: {
        }

    }
</script>
<style scoped>
    .outer-wrapper{
        min-height: 500px;
    }
    .one-top {
        box-shadow: 2px 2px 1px #aaa;
        border: 1px solid #aaa;
        overflow: hidden;
        padding: 30px;
        min-height: 500px;
    }
    .one-top-wrapper {
        position: relative;
        padding: 20px;
        border: 1px solid #eee;
        min-height: 500px;
        text-align: center;
    }
    .line-label {
        position: absolute;
        top: 2px;
        left: 60px;
        background: #fff;
        color: #333;
        font-size: 24px;
        font-weight: 700;
        padding: 0 20px;

    }

    .city-title-tag {
        font-family: 'LiSu';
        margin: 0 10px;

    }

    .line-title-tag {
        font-family: 'LiSu';
        padding: 0 5px;
        color: #345262;
    }
 .title-text-wrapper {
        border:1px solid #dad9d9;
        font-size: 20px;
        width:300px;
        margin:0 auto;
        margin-top:50px;
        padding: 10px 30px;
        border-radius: 5px;
        color:#777;
        background-color: #f7f7f7;
        cursor: pointer;  
    }

    .title-text-wrapper:hover{
        box-shadow: 2px 2px 5px #acabab;
    }

    .title-wrapper {
        height: 100px;
        text-align: center;

    }
    .city-text-wrapper{
        padding-right:10px;
        font-size: 20px;
        font-weight: 400; 
        color:#5a5a5a;
        font-family: STLiti;
        margin-right:20px;
    }

    .description-wrapper {
        text-align: center;
        padding: 20px 50px;
        font-size:20px;
    }
</style>